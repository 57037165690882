import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import MapView from "../views/Map";
import ErrorView from "../views/Error";
import DetailProject from "../views/Project";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<MapView />} />
                <Route exact path="/:nameProjectURL&:ProjectTypesURL&:countryURL&:provinceURL&:localityURL" element={<MapView />} />
                <Route exact path="project/:projectId" element={<DetailProject />} />
                <Route path='*' element={<ErrorView />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;