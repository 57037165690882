import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InitialDataContext } from "../../context/InitialDataContext";
import { MapContext } from "../../context/MapContext";
import { useChangeFilter } from "../../hooks/useChangeFilter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const initialValues = {
  projectName: "",
  projectType: 0,
  selectCountry: 0,
  selectProvince: 0,
  selectLocality: 0,
};

const initialState = {
  country: { country: null, provinces: [] },
  province: { province: null, localities: [] },
  locality: null,
};

let initialCountries = [];

let filteredProjects;

const FormFilter = () => {
  const {
    nameProjectURL,
    ProjectTypesURL,
    countryURL,
    provinceURL,
    localityURL,
  } = useParams();
  const { marker, setMarker, initialProjects } = useContext(MapContext);
  const { filter, projectType } = useContext(InitialDataContext);
  const [countries, setCountries] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedProjectType, setSelectedProjetType] = useState([]);
  const [country, setCountry] = useState(initialState.country);
  const [province, setProvince] = useState(initialState.province);
  const [locality, setLocality] = useState(initialState.locality);
  const [handleChangeFilter] = useChangeFilter(
    filteredProjects,
    projectName,
    selectedProjectType,
    country,
    province,
    locality,
    initialProjects,
    setMarker
  );

  const handleChangeReset = (event) => {
    setProjectName("");
    setSelectedProjetType([]);
    setCountry(initialState.country);
    setProvince(initialState.province);
    setLocality(initialState.locality);
    setTimeout(() => document.getElementById("btnFilter").click(), 10);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setProjectName(value);
  };

  const handleChangeProjectType = (event) => {
    const { value } = event.target;
    setSelectedProjetType(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    const selectedCountry = filter.find(
      (_country) => _country.country.id === value
    );
    setCountry(selectedCountry || initialState.country);
    setProvince(initialState.province);
    setLocality(initialState.locality);
  };

  const handleChangeProvince = (event) => {
    const { value } = event.target;
    const selectedProvince = country.provinces.find(
      (_province) => _province.province.id === value
    );
    setProvince(selectedProvince || initialState.provinces);
    setLocality(initialState.locality);
  };

  const handleChangeLocality = (event) => {
    const { value } = event.target;
    const selectedLocality = province.localities.find(
      (locality) => locality.id === value
    );
    setLocality(selectedLocality || initialState.locality);
  };

  const initialFilter = () => {
    if (nameProjectURL !== "null") {
      setProjectName(nameProjectURL);
    }
    if (ProjectTypesURL !== "null") {
      setSelectedProjetType(JSON.parse(ProjectTypesURL));
    }

    if (countryURL !== "null") {
      const countrySelected = JSON.parse(countryURL);
      const selectedCountry = filter.find(
        (_country) => _country.country.id === countrySelected.id
      );
      setCountry(selectedCountry || initialState.country);
      setProvince(initialState.province);
      setLocality(initialState.locality);
    }

    if (provinceURL !== "null") {
      const provinceSelected = JSON.parse(provinceURL);
      const selectedProvince = country?.provinces.find(
        (_province) => _province.province.id === provinceSelected.id
      );
      setProvince(selectedProvince || initialState.province);
      setLocality(initialState.locality);
    }

    if (localityURL !== "null") {
      const localitySelected = JSON.parse(localityURL);
      const selectedLocality = province.localities.find(
        (locality) => locality.id === localitySelected.id
      );
      setLocality(selectedLocality || initialState.locality);
    }
  };

  const copyURL = () => {
    let projectNameURL,
      selectedProjectTypeURL,
      countryURL,
      provinceURL,
      localityURL;

    projectName.length === 0
      ? (projectNameURL = "null")
      : (projectNameURL = projectName);

    selectedProjectType.length === 0
      ? (selectedProjectTypeURL = "null")
      : (selectedProjectTypeURL = JSON.stringify(selectedProjectType));

    country.country === null
      ? (countryURL = "null")
      : (countryURL = JSON.stringify(country.country));

    province.province === null
      ? (provinceURL = "null")
      : (provinceURL = JSON.stringify(province.province));

    locality === null
      ? (localityURL = "null")
      : (localityURL = JSON.stringify(locality));

    const url =
      process.env.REACT_APP_FRONTEND +
      projectNameURL +
      "&" +
      selectedProjectTypeURL +
      "&" +
      countryURL +
      "&" +
      provinceURL +
      "&" +
      localityURL;
    window.location.href = url;
  };

  useEffect(() => {
    initialCountries = filter.map((element) => {
      return element.country;
    });
    setCountries(initialCountries);
    setProjectTypes(projectType);
    if (nameProjectURL != null || ProjectTypesURL != null || countryURL != null || provinceURL != null || localityURL != null ) {
      initialFilter();
      setTimeout(() => document.getElementById("btnFilter").click(), 10);
    }
  }, [filter, projectType]);

  return (
    <List>
      <ListItem className="navbar__list__item" disablePadding>
        <FormControl className="navbar__input" variant="standard">
          <Input
            id="input__name__proyect"
            placeholder="Nombre de Proyecto"
            name="projectName"
            size="normal"
            onChange={handleChange}
            value={projectName}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src={`${
                    process.env.REACT_APP_FRONTEND +
                    "assets/images/icon-input.svg"
                  }`}
                  alt="Logo Druky"
                  className="navbar__logo"
                />
              </InputAdornment>
            }
          />
          <FormHelperText id="component-helper-text">
            Selecciona un proyecto por su nombre.
          </FormHelperText>
        </FormControl>
      </ListItem>
      <ListItem className="navbar__list__item" disablePadding>
        <FormControl
          className="navbar__select__multiple"
          sx={{ s: 1, width: 300 }}
        >
          <InputLabel id="demo-multiple-chip-label">
            Tipos de Proyecto
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedProjectType}
            onChange={handleChangeProjectType}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {projectType.map((projectType) => (
              <MenuItem key={projectType.id} value={projectType.name}>
                {projectType.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Filtra por tipo de proyecto.</FormHelperText>
        </FormControl>
      </ListItem>
      <ListItem className="navbar__list__item" disablePadding>
        <FormControl className="navbar__select">
          <TextField
            id="standard-select-currency"
            select
            onChange={handleChangeCountry}
            value={country?.country?.id || initialValues.selectCountry}
            name="selectCountry"
            helperText="Filtra por país."
            variant="standard"
          >
            <MenuItem value={initialValues.selectCountry}>País</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </ListItem>
      <ListItem className="navbar__list__item" disablePadding>
        <FormControl className="navbar__select">
          <TextField
            id="standard-select-currency"
            select
            onChange={handleChangeProvince}
            value={province?.province?.id || initialValues.selectProvince}
            name="selectProvince"
            helperText="Filtra por una provincia o estado."
            variant="standard"
          >
            <MenuItem value={initialValues.selectProvince}>
              Provincia o Estado
            </MenuItem>
            {country.provinces.map((province) => (
              <MenuItem key={province.province.id} value={province.province.id}>
                {province.province.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </ListItem>
      <ListItem className="navbar__list__item" disablePadding>
        <FormControl className="navbar__select">
          <TextField
            id="standard-select-currency"
            select
            onChange={handleChangeLocality}
            value={locality?.id || initialValues.selectLocality}
            name="select_city"
            helperText="Filtra por una ciudad."
            variant="standard"
          >
            <MenuItem value={initialValues.selectLocality}>Ciudad</MenuItem>
            {province.localities.map((locality) => (
              <MenuItem key={locality.id} value={locality.id}>
                {locality.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </ListItem>
      <div className="navbar__count__project">
        Cantidad <span>{marker.length}</span>
      </div>
      <ListItem className="navbar__list__item navbar__button" disablePadding>
        <Button
          onClick={handleChangeFilter}
          className="button__white"
          variant="outlined"
          size="large"
          id="btnFilter"
        >
          Filtrar
        </Button>
        <RestartAltIcon
          onClick={handleChangeReset}
          className="icon__cleaning"
        />
      </ListItem>
      <ListItem className="navbar__list__item navbar__button" disablePadding>
        <Button
          onClick={copyURL}
          className="button__green"
          variant="outlined"
          size="large"
        >
          Compartir
        </Button>
      </ListItem>
    </List>
  );
};

export default FormFilter;
