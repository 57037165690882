import { getWithoutToken } from '../helpers/get';

const getProjectType = async () => {
    const response = await getWithoutToken('getProjectTypesPublic')
    if (response.data.status === 201) {
        return response.data;
    }
    // errores...
    return [];
}
export default getProjectType;