export function useChangeFilter(
  filteredProjects,
  projectName,
  selectedProjectType,
  country,
  province,
  locality,
  initialProjects,
  setMarker
) {
  const handleChangeFilter = (event) => {
    let auxTypesProjects = [];
    switch (true) {
      // FILTRAR SOLO POR NOMBRE
      case projectName !== "" &&
        selectedProjectType.length === 0 &&
        country.country === null &&
        province.province === null &&
        locality === null:
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO SOLO POR TIPO DE PROYECTO
      case projectName === "" &&
        selectedProjectType.length > 0 &&
        country.country === null &&
        province.province === null &&
        locality === null:
        filteredProjects = initialProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        filteredProjects = auxTypesProjects;
        break;

      // FILTRO SOLO POR PAIS
      case projectName === "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province === null &&
        locality === null:
        filteredProjects = initialProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO SOLO POR PROVINCIA
      case projectName === "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province !== null &&
        locality === null:
        filteredProjects = initialProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO SOLO POR LOCALIDAD
      case projectName === "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province !== null &&
        locality !== null:
        filteredProjects = initialProjects.filter((project) => {
          return project.locality.indexOf(locality.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR NOMBRE Y TIPO DE PROYECTO
      case projectName !== "" &&
        selectedProjectType.length > 0 &&
        country.country === null &&
        province.province === null &&
        locality === null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });

        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = filteredProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        filteredProjects = auxTypesProjects;
        break;

      // FILTRO POR NOMBRE, TIPO DE PROYECTO Y PAIS
      case projectName !== "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province === null &&
        locality === null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });

        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = filteredProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR NOMBRE, TIPO DE PROYECTO, PAIS Y PROVINCIA
      case projectName !== "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province !== null &&
        locality === null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });

        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = filteredProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });

        break;

      // FILTRO POR NOMBRE, TIPO DE PROYECTO, PAIS, PROVINCIA Y LOCALIDAD
      case projectName !== "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province !== null &&
        locality !== null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });

        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = filteredProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        // LOCALIDAD
        filteredProjects = filteredProjects.filter((project) => {
          return project.locality.indexOf(locality.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR NOMBRE Y PAIS
      case projectName !== "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province === null &&
        locality === null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });
        // PAIS
        filteredProjects = filteredProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR NOMBRE, PAIS Y PROVINCIA
      case projectName !== "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province !== null &&
        locality === null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });
        // PAIS
        filteredProjects = filteredProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR NOMBRE, PAIS, PROVINCIA Y LOCALIDAD
      case projectName !== "" &&
        selectedProjectType.length === 0 &&
        country.country !== null &&
        province.province !== null &&
        locality !== null:
        // FILTRAMOS POR NOMBRE
        filteredProjects = initialProjects.filter((project) => {
          return project.name
            .toLowerCase()
            .indexOf(projectName.toLowerCase()) !== -1
            ? project
            : null;
        });
        // PAIS
        filteredProjects = filteredProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        // LOCALIDAD
        filteredProjects = filteredProjects.filter((project) => {
          return project.locality.indexOf(locality.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR TIPO DE PROYECTO Y PAIS
      case projectName === "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province === null &&
        locality === null:
        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = initialProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR TIPO DE PROYECTO, PAIS Y PROVINCIA
      case projectName === "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province !== null &&
        locality === null:
        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = initialProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        break;

      // FILTRO POR TIPO DE PROYECTO, PAIS, PROVINCIA Y LOCALIDAD
      case projectName === "" &&
        selectedProjectType.length > 0 &&
        country.country !== null &&
        province.province !== null &&
        locality !== null:
        // FILTRAMOS POR TIPO DE PROYECTOS
        filteredProjects = initialProjects.filter((project) => {
          project.projectTypes.forEach((projectTypes) => {
            selectedProjectType.forEach((_selectedProjectType) => {
              if (projectTypes.name === _selectedProjectType) {
                auxTypesProjects.push(project);
              }
            });
          });
          let set = new Set(auxTypesProjects.map(JSON.stringify));
          auxTypesProjects = Array.from(set).map(JSON.parse);
          return auxTypesProjects;
        });
        // PAIS
        filteredProjects = auxTypesProjects.filter((project) => {
          return project.country.indexOf(country.country.name) !== -1
            ? project
            : null;
        });
        // PROVINCIA
        filteredProjects = filteredProjects.filter((project) => {
          return project.province.indexOf(province.province.name) !== -1
            ? project
            : null;
        });
        // LOCALIDAD
        filteredProjects = filteredProjects.filter((project) => {
          return project.locality.indexOf(locality.name) !== -1
            ? project
            : null;
        });
        break;

      default:
        filteredProjects = initialProjects;
        break;
    }
    setMarker(filteredProjects);
  };

  return [handleChangeFilter];
}
