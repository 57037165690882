import { useEffect, useMemo, useState } from "react";
import { getFilter, getInitialData, getProjectType } from "../services/Data";
import { useLoader } from "./useLoader";

export const useInitialData = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const { loader, setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    // PROJECTS
    getInitialData()
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        setData(error);
      });
    //   LOCALITIES
    getFilter()
      .then((filters) => {
        setFilter(filters.data);
      })
      .catch((error) => {
        setFilter(error);
      });
    //   PROJECT TYPES
    getProjectType()
      .then((projectTypes) => {
        setProjectType(projectTypes.project_types);
        setTimeout(() => setLoading(false),1000);
      })
      .catch((error) => {
        setFilter(error);
      });
  }, [setLoading]);

  const value = useMemo(
    () => ({
      data,
      setData,
      loader,
      setLoading,
      filter,
      setFilter,
      projectType,
      setProjectType,
    }),
    [data, setData, loader, setLoading, filter, setFilter, projectType, setProjectType]
  );

  return value;
};
