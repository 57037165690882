import { IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { useNavigate  } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open = false, setOpen = false, responsive = false }) => {
  const navigate  = useNavigate();
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <AppBar
      className={
        responsive ? "navbar__visor navbar__visor__responsive" : "navbar__visor"
      }
      position="fixed"
      open={open}
    >
      <Toolbar>
        <img
          src={`${
            process.env.REACT_APP_FRONTEND + "assets/images/icon-gbs-white.svg"
          }`}
          alt="Logo Druky"
          className="navbar__logo"
        />
        {responsive && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
            className="navbar__logo__compress"
          >
            <MenuIcon />
          </IconButton>
        )}
        {!responsive && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={goBack}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
