import { getWithoutToken } from '../helpers/get';

const getInitialData = async () => {
    const response = await getWithoutToken('getProjectsPublic')
    if (response.data.status === 201) {
        return response.data;
    }
    // errores...
    return [];
}
export default getInitialData;