import React, { useContext } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./styles.scss";
import L from "leaflet";
import { MapContext } from "../../context/MapContext";
import { Link } from "react-router-dom";

const MapScreen = () => {
  const { marker } = useContext(MapContext);

  const myIcon = L.icon({
    iconUrl: process.env.REACT_APP_FRONTEND + "assets/images/pin.png",
    iconSize: [10, 10],
  });

  return (
    <MapContainer
      center={{ lat: "-26.830862702799365", lng: "-65.20363055593273" }}
      zoom={5}
      maxZoom={18}
      minZoom={3}
      className="leaflet-container"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {marker.map((element) => {
        return (
          <Marker
            icon={myIcon}
            position={[element.latitude, element.longitude]}
            key={element.id + element.name}
          >
            <Popup>
              {element.name} <br />{" "}
              <span className="description__project">
                {element.observation}
              </span>
              <br />
              <div className="content__button__detail">
                <Link
                  variant="outlined"
                  size="large"
                  to={"/project/" + element.id}
                >
                  Ver
                </Link>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapScreen;
