import React from 'react'


const ErrorView = () => {

    return (
        <h1>Error</h1>
    );
}

export default ErrorView;
