import React from "react";

const MapFooter = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      Copyright © {year} GBS Architectural Srl | Powered by&nbsp;
      <strong> Filevel Software ®</strong>
    </footer>
  );
};

export default MapFooter;
