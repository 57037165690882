import { React } from "react";
import AppRouter from "../../router/AppRouter";
import { useInitialData } from "../../hooks/useInitialData";
import { InitialDataContext } from '../../context/InitialDataContext';


const MainApp = () => {
  const { data, setData, loader, setLoading, filter, setFilter, projectType, setProjectType } = useInitialData();
  return (
    <InitialDataContext.Provider
      value={{ data, setData, loader, setLoading, filter, setFilter, projectType, setProjectType }}
    >
      <AppRouter />
    </InitialDataContext.Provider>
  );
};

export default MainApp;
