import React, { useContext, useEffect, useState } from "react";
import { MapScreen, MapFilter, MapFooter } from "../../components/Map";
import { InitialDataContext } from "../../context/InitialDataContext";
import "./styles.scss";
import { MapContext } from "../../context/MapContext";
import { Backdrop, CircularProgress } from "@mui/material";

let initialProjects = {};
const MapView = () => {
  const { data, loader } = useContext(InitialDataContext);
  const [marker, setMarker] = useState([]);

  useEffect(() => {
    initialProjects = data.map((element) => {
      return {
        id: element.project.id,
        name: element.project.name,
        observation: element.project.observation,
        latitude: element.address[0].latitude,
        longitude: element.address[0].longitude,
        country: element.address[0].name_country,
        province: element.address[0].name_province,
        locality: element.address[0].name_locality,
        projectTypes: element.project_types,
      };
    });
    setMarker(initialProjects);
  }, [data]);
  return (
    <MapContext.Provider value={{ marker, setMarker, initialProjects, loader }}>
      <MapFilter />
      <div className="container-map">
        {loader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {!loader && (
          <>
            <MapScreen />
            <MapFooter />
          </>
        )}
      </div>
    </MapContext.Provider>
  );
};

export default MapView;
