import { Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Map/shared/Navbar";
import { InitialDataContext } from "../../context/InitialDataContext";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "./styles.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetailProject = () => {
  const { projectId } = useParams();
  const { data } = useContext(InitialDataContext);
  const [projectSelected, setProjectSelected] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const projectDetail = data.filter(
      (_project) => _project.project.id == projectId
    );
    setProjectSelected(projectDetail);
    console.log(projectSelected);
  }, [data, projectId]);

  return (
    <>
      <Navbar />
      <div className="container__detail__project">
        <Container maxWidth="false">
          <h2>{projectSelected[0]?.project.name}</h2>
          <Stack
            direction="row"
            className="content__detail__project__types"
            spacing={1}
          >
            {projectSelected[0]?.project_types.map((project) => {
              return (
                <Chip
                  key={project.name}
                  label={project.name}
                  size="small"
                  variant="outlined"
                />
              );
            })}
          </Stack>
          <h4>{projectSelected[0]?.project.observation}</h4>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                className="container__tabs"
              >
                <Tab className="content__tabs__title" label="Descripción" {...a11yProps(0)} />
                <Tab className="content__tabs__title" label="Documentos" {...a11yProps(1)} />
                <Tab className="content__tabs__title" label="Galería" {...a11yProps(2)} />
                <Tab className="content__tabs__title" label="Links" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel className="content__tabs" value={value} index={0}>
              Descripción
            </TabPanel>
            <TabPanel className="content__tabs" value={value} index={1}>
              Documentos
            </TabPanel>
            <TabPanel className="content__tabs" value={value} index={2}>
              Galería
            </TabPanel>
            <TabPanel className="content__tabs" value={value} index={3}>
              Links
            </TabPanel>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default DetailProject;
